import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {Card, Col, Row, Stack} from "react-bootstrap";
import {action as toggleMenu} from 'redux-burger-menu';
import {States} from "../interfaces/interface";
import {connectVehicle, defaultVehicle} from "../reducers/mainReducer";


const Header = () => {
    const dispatch = useDispatch()
    const isOpen = useSelector((state : {burgerMenu : {isOpen : boolean}}) => state.burgerMenu.isOpen)
    const name = useSelector((state : States) => state.main.userInfo.name)
    const isAuthenticated = useIsAuthenticated();


    const [openUser, setOpenUser] = useState(false)
    const { instance } = useMsal();

    return (
        <Row className={"w-100 position-fixed"} style={styles.header}>
            {!isAuthenticated && process.env.REACT_APP_AUTHENTICATION === "YES" ?
                <Col xs={12} className={"d-flex justify-content-center "}>
                    <img className={`img-fluid`} style={styles.englab} src={"./icon/menu/englab.png"}/>
                </Col>
                :
                <>
                    <Col xs={2} sm={1} className={"align-items-center d-flex justify-content-center position-relative"} style={styles.corner}>

                        <img onClick={() => dispatch(toggleMenu(!isOpen))} className={`img-fluid`} src={"./icon/menu/burger.png"} style={styles.burgerIcon}/>
                    </Col>
                    <Col xs={8} sm={10} className={"d-flex justify-content-center align-items-center"}>
                        <img className={`img-fluid`} style={styles.englab} src={"./icon/menu/englab.png"}/>
                    </Col>

                    {isAuthenticated &&
                        <Col xs={2} sm={1} className={"d-flex justify-content-center align-items-center"}>

                            <img className={`img-fluid`} style={styles.englab} src={"./icon/menu/user.png"} onClick={() => {
                                setOpenUser(!openUser)
                            }}/>
                            {openUser &&
                                <div className={"d-flex justify-content-end postion-relative"}>
                                    <Card style={styles.userMenu}>
                                        <Stack gap={1} className={"m-2 d-flex"}>
                                            <div className={"d-flex"} style={{justifyContent : "end"}}>
                                                {name}
                                            </div>
                                            <div className={"d-flex mt-2"} style={{cursor : "pointer", justifyContent : "end"}} onClick={async () => {
                                                dispatch(connectVehicle(defaultVehicle))
                                                await instance.logoutRedirect()
                                            }}>
                                                Logout
                                            </div>
                                        </Stack>
                                    </Card>
                                </div>
                            }
                        </Col>
                    }
                </>
            }

        </Row>
    )
}

export default Header

const styles = ({

    header : {
        background: "linear-gradient(90deg, rgba(16,159,247,1) 0%, rgba(128,1,223,1) 100%)",
        top : 0,
        zIndex: "10",
    },

    englab : {
        maxHeight: "2.5rem",
    },
    burgerIcon : {
        maxHeight: 25,
        cursor : "pointer"
    },
    corner : {
        minHeight : "3rem"
    },
    userMenu : {
        display : "flex",

        position : "absolute" as 'absolute',
        padding : 0,
        top : "3rem"
    }

})
