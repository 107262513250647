
import mqtt, {AsyncMqttClient} from 'async-mqtt';
import uuid from 'uuid-random';
import {MessageType, ReceivePackets, SendPackets} from "../interfaces/packet";

export const id = uuid();


interface MqttProtocol {
    /**
     *
     * @param payload payload of the packet
     * @param topic only for mqtt protocol specify on which topic the packet will be sent
     * @param qos only for mqtt protocol specify the quality of service of the packet 0 = fire and forget,
     * 1 = at least one time,
     * 2 = exactly one time
     *
     */
    send : (topic : string, payload : SendPackets, qos? : 0 | 1 | 2 ) => Promise<void>
    receive : (callback : (message : ReceivePackets) => void) => void
    disconnect : () => Promise<void>
    onConnectionClosed : (callback : () => void) => void
    subscribe : (topics : string[]) => Promise<void>
    unsubscribe : (topics : string[]) => Promise<void>
}

export const mqttConnect = () : MqttProtocol => {
    const protocol = window.location.protocol.replace('http', 'ws')
    const client = mqtt.connect(`${protocol}//${window.location.host}/mqtt`, {
        clientId : id,
        clean: true,
        username : "web-client",
        password : "innolab",
        connectTimeout: 4000,
        reconnectPeriod: 1000,
        keepalive : 2,
        will : {
            topic : `${id}/disconnected`,
            qos : 1,
            payload : JSON.stringify({messageType : MessageType.DISCONNECTED_CLIENT, clientId : id}),
            retain : false
        }
    })

    client.on("disconnect", () => {
        console.log("DISCONNECT")
    })

    client.on("connect", () => {console.log("Connection Successful")})

    return mqttProtocol(client);
}

const mqttProtocol = (client : AsyncMqttClient) : MqttProtocol => ({
    async send(topic , payload, qos  = 0) {
        await client.publish(topic, JSON.stringify(payload), {qos : qos})
    },
    receive(callback) {
        client.on("message", (topic, payload) => {
            callback(JSON.parse(payload.toString()))
        })
    },
    async disconnect() {
        await client.end();
    },
    async subscribe(topics ) {
        await client.subscribe(topics);
    },
    async unsubscribe(topics ) {
        await client.unsubscribe(topics);
    },
    onConnectionClosed(callback) {
        client.on("close", () => {
            callback()
        })
    }
})