import {Vehicle} from "./vehicle";
import {MissionType} from "./packet";

export enum ScreenType {
    HOME,
    DIAGNOSTIC,
    CONFIGURATION
}

export enum ViewType {
    VIDEO = "Video",
    MAP = "Map"
}

export enum Role {
    ADMIN = "admin",
    GUEST = "guest"
}

export type UserInfo = {
    accessToken : string
    role : Role,
    name : string
}

export enum MapDrawMode {
    CANCEL,
    DRAWING,
    VALIDATE,
    NONE,
}

interface MainState {
    vehicleList : Vehicle[]
    selectedVehicle : Vehicle,
    activeScreen : ScreenType
    connectionToast : {
        type : "disconnection" | "connection" | ""
        value : string
    },
    view : ViewType,
    fullscreen : boolean,
    userInfo : UserInfo,
    canWatchStream : false,
    map : {
        displayLoadPath : true,
    },
    windowDimensions : { width : number , height : number }
}

interface ConfigurationState {
    missionDropdown : MissionType
}

interface HomeState {
    map : {
        longitude : number,
        latitude : number
        zoom : number
        control : boolean,
        view : "Streets" | "Satellite"
        mapDrawMode : MapDrawMode,
        drawWaypoints : number[][],
    },
}

export interface States {
    main : MainState
    configuration : ConfigurationState
    home : HomeState
}
