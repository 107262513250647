import Home from "./home/Home";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {defaultVehicle, initMqttConnection, setInit, setScreen, setUserInfo} from "../reducers/mainReducer";
import { Col, Container, Row, Stack, Toast, ToastContainer} from "react-bootstrap";
import { useIsAuthenticated, useMsal} from "@azure/msal-react";

import BurgerMenu from "./components/BurgerMenu";
import {Role, ScreenType, States} from "../interfaces/interface";
import Header from "../components/Header";
import {loginRequest, LoginScreen} from "./login/MiscorsoftSignIn";
import Configuration from "./configuration/Configuration";


const DisplayToast = () => {

    const toast = useSelector((state : States) => state.main.connectionToast)
    const [show, setShow] = useState(false);

    useEffect(() => {
        if ("" !== toast.type)
            setShow(true)
    }, [toast])



    return (
        <ToastContainer position={"top-end"}  className="position-fixed p-3" style={{marginTop : 3 + "rem"}}>
            <Toast onClose={() => setShow(false)} show={show} bg={"disconnection" === toast.type ? "danger"  : "success"} delay={3000} autohide>
                <Toast.Header>
                    <strong className="me-auto">{toast.type}</strong>
                </Toast.Header>
                <Toast.Body className={'Dark' && 'text-white'}>
                    {toast.value}
                </Toast.Body>
            </Toast>
        </ToastContainer>
    )
}

const SideMenu = () => {
    const dimensions = useSelector((state : States) => state.main.windowDimensions)
    const dispatch = useDispatch()
    const activeScreen = useSelector((state : States) => state.main.activeScreen)
    const userInfo = useSelector((state : States) => state.main.userInfo)
    const selectedVehicle = useSelector((state : States) => state.main.selectedVehicle)


    return (
        <>
            {dimensions.width >= 576 &&
                <Col xs={1} style={styles.sideMenu}>
                    <Stack gap={5} className={"w-100 h-100 align-items-center"} >
                        <div
                            style={{cursor: "pointer", opacity: activeScreen === ScreenType.HOME ? 1 : 0.3}}
                            onClick={() => dispatch(setScreen(ScreenType.HOME))}
                        >
                            <img className={`img-fluid`} style={styles.sideMenuIcon} src={"./icon/menu/home.png"}/>
                        </div>
                        {userInfo.role === Role.ADMIN && selectedVehicle != defaultVehicle &&
                            <div
                                style={{cursor: "pointer", opacity: activeScreen === ScreenType.CONFIGURATION ? 1 : 0.3}}
                                onClick={() => dispatch(setScreen(ScreenType.CONFIGURATION))}
                            >
                                <img className={`img-fluid`} style={styles.sideMenuIcon} src={"./icon/menu/configuration.png"}/>
                            </div>
                        }
                    </Stack>
                </Col>
            }
        </>
    )
}

const screens = [
    <Home/>,
    <div/>,
    <Configuration/>
]

const App = () => {
    const dispatch = useDispatch()
    const activeScreen = useSelector((state : States) => state.main.activeScreen)
    const { instance, accounts} = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const determineRole = (response) => {
        if (window.location.hostname === 'robotcloud.local') {
            return Role.ADMIN
        }

        return response.idTokenClaims["roles"] ? response.idTokenClaims["roles"][0] : Role.GUEST
    }

    useEffect(() => {
        const requestToken = async () => {
            const request = {
                ...loginRequest,
                account: accounts[0]
            };

            let response;
            try {
                response = await instance.acquireTokenSilent(request)
            } catch (e) {
                response = await instance.acquireTokenPopup(request)
            }
            dispatch(setUserInfo({accessToken : response.accessToken, name : response.account?.name as string,  role: determineRole(response)}))
            console.log('User connected', {accessToken : response.accessToken, name : response.account?.name as string,  role: determineRole(response)})
        }

        if (isAuthenticated)
            requestToken()

        if (isAuthenticated || process.env.REACT_APP_AUTHENTICATION === "NO") {
            dispatch(setInit())
            dispatch(initMqttConnection())
        }
    }, [isAuthenticated])




    return (
        <>
            {!isAuthenticated && process.env.REACT_APP_AUTHENTICATION === "YES" ?
                <LoginScreen/>
                :
                <>
                    <BurgerMenu/>
                    <Container fluid >
                        <Header/>
                        <Row className={"h-100"} style={styles.body} >
                            <SideMenu/>
                            <Col xs={12} sm={11}>
                                {screens[activeScreen]}
                            </Col>
                        </Row>
                    </Container>
                    <DisplayToast/>
                </>
            }
        </>
    )
}

export default App

const styles = ({
    container : {
        minHeight : "100vh",
        position : "relative" as 'relative',
    },
    corner : {
        minHeight : "3rem"
    },
    header : {
        background: "linear-gradient(90deg, rgba(16,159,247,1) 0%, rgba(128,1,223,1) 100%)",
        top : 0,
        zIndex: "10",
    },

    englab : {
        maxHeight: "2.5rem",
    },
    burgerIcon : {
        maxHeight: 25,
        cursor : "pointer"
    },
    sideMenu : {
        paddingTop: "3rem",
        backgroundColor: "whitesmoke",
    },
    sideMenuIcon : {
        maxHeight : 40
    },
    body : {
        marginTop: "3rem",
        minHeight : "calc(100vh - 3rem)",
    },
    footer : {
        height : "3rem",
        color : "white",
        background : "linear-gradient(90deg, rgba(16,159,247,1) 0%, rgba(128,1,223,1) 100%)"
    }
})
