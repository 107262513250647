import {call, fork, put, select, take, takeLatest} from 'redux-saga/effects';
import config from "../vehicles-config.json"

import {
    ActionType as MainActionType,
    confirmCommand,
    ConnectVehicleAction,
    defaultVehicle,
    SendCommandAction,
    setAvailableVehicle,
    setDisconnectedVehicle,
    setSelectedVehicle,
    setWaypoints,
    updateVehicle,
} from "../reducers/mainReducer";
import {id, mqttConnect} from "../protocol/mqtt";
import {eventChannel} from "redux-saga";
import {MessageType, ReceivePackets} from "../interfaces/packet";
import {MapDrawMode, States} from "../interfaces/interface";
import {ActionType as ConfigurationActionType, SendMission} from "../reducers/configurationReducer";
import {ActionType as HomeActionType, setMapDrawMode, SetMapDrawModeAction } from "../reducers/homeReducer";


//20.188.57.119
const mqttClient = mqttConnect()

const vehicleTopics = (id : string) => config.vehicleTopics.map(topic => id + topic)

function *sendCommand(action : SendCommandAction) {
    const selectedVehicle = yield select((state : States) => state.main.selectedVehicle)

    yield call(mqttClient.send, `${selectedVehicle.id}/send/command`, {messageType : MessageType.COMMAND,command : action.command},1)
}

function* sendWaypoints(action : SetMapDrawModeAction) {

    if (action.mapDrawMode === MapDrawMode.VALIDATE) {
        const selectedVehicle = yield select((state: States) => state.main.selectedVehicle)

        yield call(mqttClient.send, `${selectedVehicle.id}/send/waypoints`, {
            messageType: MessageType.DRAW_WAYPOINTS,
            waypoints: action.waypoints
        })
        yield put(setMapDrawMode(MapDrawMode.NONE))
    }
}

function *sendMission(action : SendMission) {
    const selectedVehicle = yield select((state : States) => state.main.selectedVehicle)

    yield call(mqttClient.send, `${selectedVehicle.id}/mission`, {messageType : MessageType.MISSION, missionType : action.mission, command : action.command},1)
}

function *unsubscribeFromVehicle(vehicleId : string) {
    yield call(mqttClient.send, `${vehicleId}/dataset/request`,{messageType : MessageType.REQUEST_DATASET ,request : false, clientId : id}, 1)
    yield call(mqttClient.unsubscribe, vehicleTopics(vehicleId) )
}

function *disconnect() {
    yield call(mqttClient.send, `${id}/disconnected`, {messageType : MessageType.DISCONNECTED_CLIENT, clientId: id}, 1 )
    yield call(mqttClient.disconnect)
}

function *connectVehicle(action : ConnectVehicleAction) {
    const selectedVehicle = yield select((state : States) => state.main.selectedVehicle)

    yield call(unsubscribeFromVehicle, selectedVehicle.id)

    if (action.selectedVehicle.id !== defaultVehicle.id) {
        yield call(mqttClient.subscribe, vehicleTopics(action.selectedVehicle.id))
        yield call(mqttClient.send, `${action.selectedVehicle.id}/dataset/request`, {
            messageType: MessageType.REQUEST_DATASET,
            request: true,
            clientId: id
        }, 1)
    }

    yield put(setSelectedVehicle(action.selectedVehicle))
}


const clientReceiver = () => {
    return eventChannel((emitter) => {
        mqttClient.receive((payload) => {
            emitter(payload)
        })
        return () => {
            return
        }
    })
}

const onConnectionCloseEmitter = () => {
    return eventChannel((emitter) => {
        mqttClient.onConnectionClosed(() => {
            emitter("close")
        })
        return () => {
            return
        }
    })
}

function *onConnectionClosed() {
    const channel = yield call(onConnectionCloseEmitter)
    while (true) {
        yield take(channel);
        console.error("Connection Closed")
        yield put(setSelectedVehicle(defaultVehicle))

    }
}

function *receive() {

    const channel = yield call(clientReceiver)
    try {
        while (true) {
            const newRequest : ReceivePackets  = yield take(channel);
            switch (newRequest.messageType) {
                case MessageType.VEHICLE_INFO :
                    yield put(updateVehicle(newRequest.data))
                    break;
                case MessageType.CONNECTED_DEVICE :
                    yield put(setAvailableVehicle(newRequest.id))
                    break;
                case MessageType.DISCONNECTED_DEVICE :
                    yield call(unsubscribeFromVehicle, newRequest.id)
                    yield put(setDisconnectedVehicle(newRequest.id))
                    break;
                case MessageType.COMMAND_CONFIRMATION :
                    yield put(confirmCommand(newRequest.command))
                    break;
                case MessageType.WAYPOINTS :
                    yield put(setWaypoints(newRequest.data))
                    break;
                default :
                    console.error("unknown message type", newRequest)
            }
        }
    } catch (e) {
        console.error(e)
    }
}

function *initMqttConnection() {
    try {
        yield call(mqttClient.subscribe, config.topics)
        yield fork(receive)
        yield fork(onConnectionClosed)
        yield call(mqttClient.send, "whoIsConnected", {messageType : MessageType.WHO_IS_CONNECTED},1)
    } catch (e) {
        console.error(e)
    }
}

function* protocolSaga() {
    yield takeLatest(MainActionType.SEND_COMMAND, sendCommand);
    yield takeLatest(MainActionType.CONNECT_VEHICLE, connectVehicle)
    yield takeLatest(MainActionType.INIT_MQTT_CONNECTION, initMqttConnection)
    yield takeLatest(MainActionType.DISCONNECT, disconnect)
    yield takeLatest(ConfigurationActionType.SEND_MISSION, sendMission)
    yield takeLatest(HomeActionType.SET_MAP_DRAW_MODE, sendWaypoints)
}

export default protocolSaga;
