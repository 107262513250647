import {Card, Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import React from "react";
import {States} from "../../../interfaces/interface";

const text = ["Mode", "Safety Trigger", "Mission"]

const displayEnum = (enums : string[]) => {
    const dimensions = useSelector((state : States) => state.main.windowDimensions)

    return (text.map((item, index) =>
        <Col xs={12} md={3} lg={4} key={index} className={"d-flex justify-content-center"}>
            <Row className={"align-items-center d-flex w-100"}>
                {index != 0 && dimensions.width <= 768 && <div style={styles.divider}/> }
                <Col xs={12} lg={2} xl={12} className={"d-flex justify-content-center mb-2 mb-lg-0 mb-xl-2"}>
                    <div>{item}</div>
                </Col>
                <Col xs={12} lg={10} xl={12}>
                    <Card className={`text-center`} style={styles.valueFontSize} body>{enums[index] ? enums[index].toUpperCase() : "UNDEFINED" }</Card>
                </Col>
            </Row>
        </Col>)
    )
}


const MissionPanel = () => {

    const mode = useSelector((state : States) => state.main.selectedVehicle.dataset.system.mode)
    const safetyTrigger = useSelector((state : States) => state.main.selectedVehicle.dataset.system.safetyTrigger)
    const missionType = useSelector((state : States) => state.main.selectedVehicle.dataset.system.missionInProgress)

    return (
        <Card className={`panel h-100`}>

            <Row className={"p-0 m-0 justify-content-evenly align-items-center d-flex"} >
                {displayEnum([mode, safetyTrigger, missionType])}
            </Row>
        </Card>
    )
}

export default MissionPanel

const styles = ({
    valueFontSize : {
        fontSize : 14
    },
    divider : {
        backgroundColor: "lightgray",
        marginLeft: "5%",
        width: "90%",
        height: 1,
        marginBottom: 8,
        marginTop: 8,
    }
})