
import {ActionType as MainActionType, resizeWindow} from "../reducers/mainReducer";
import protocolSaga from "./protocolSaga";
import {call, put, select, takeLatest, all, take, fork} from 'redux-saga/effects';
import {setCanWatchStream} from "../reducers/mainReducer";
import {States} from "../interfaces/interface";
import {eventChannel} from "redux-saga";


function *authenticateToVideoSever() {
    const userInfo = yield select((state : States) => state.main.userInfo)
    const selectedVehicle = yield select((state : States) => state.main.selectedVehicle)

    const myHeaders = new Headers({
        "Content-Type" : "application/json"
    });

    try {
        yield call(fetch, `${window.location.protocol}//${window.location.host}/video/subscribeTo`, {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({accessToken: userInfo?.accessToken, vehicleId : selectedVehicle.id })

        })
        yield put(setCanWatchStream(true))
    } catch (e) {
        console.error("Authentication failed")
        yield put(setCanWatchStream(false))
    }

}

const windowResizeEmitter = () => {
    return eventChannel((emitter) => {
        window.addEventListener("resize", () => {
            emitter({width: window.innerWidth, height: window.innerHeight})
        })
        return () => {
            window.removeEventListener("resize", () => 1)
        }
    })
}

function *windowResize() {
    const channel = yield call(windowResizeEmitter)
    while (true) {
        const dimensions = yield take(channel);
        yield put(resizeWindow(dimensions))
    }
}

function* init() {
    yield fork(windowResize)
}

function* mainSaga() {
    yield takeLatest(MainActionType.AUTHENTICATE_TO_VIDEO_SERVER, authenticateToVideoSever);
    yield takeLatest(MainActionType.INIT, init);
}

export default function* rootSaga() {
    yield all([
        mainSaga(),
        protocolSaga()
    ])
}

