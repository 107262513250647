import {Card, Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import React from "react";
import {States} from "../../../../interfaces/interface";


const Inclination = () => {
    const pitchAngle = useSelector((state : States) => state.main.selectedVehicle.dataset.vehicleInfo.pitchDeg)
    const rollAngle = useSelector((state : States) => state.main.selectedVehicle.dataset.vehicleInfo.rollDeg)

    return (
        <Col xs={4} sm={10} lg={10} xl={12} className={"mb-sm-3"}>
            <Row className={"align-items-center d-flex h-100 justify-content-evenly"}>
                <Col xs={12} sm={6} lg={5} xl={4} className={`text-center`}>
                    <Card.Img src={"./kipp_side.png"} className={`img-fluid`} style={{...styles.icon ,transform : `rotate(${pitchAngle}deg)`}}/>
                    <Card>
                        {pitchAngle}°
                    </Card>
                </Col>
                <Col xs={12} sm={6} lg={5} xl={4} className={`text-center`}>
                    <Card.Img src={"./kipp_back.png"} className={`img-fluid`} style={{...styles.icon, transform : `rotate(${rollAngle}deg)`}}/>
                    <Card>
                        {rollAngle}°
                    </Card>
                </Col>
            </Row>
        </Col>
    )
}

export default Inclination

const styles = ({
    icon : {
        maxWidth: 100,
        minWidth: 30,
        overflow: "hidden",
    },
})