import {
    Command,
    InfoDataset,
    MissionType,
    SafetyTrigger,
    Status,
    VehicleMode,
    WaypointDataset
} from "../interfaces/packet";
import {refreshArray} from "../utils/array";
import {Vehicle} from "../interfaces/vehicle";
import {ScreenType, UserInfo, ViewType} from "../interfaces/interface";
import config from "./../vehicles-config.json"

export enum ActionType {
    SET_DISCONNECTED_VEHICLE = "SET_DISCONNECTED_VEHICLE",
    UPDATE_VEHICLE_INFO = "UPDATE_VEHICLE_INFO",
    CONNECT_VEHICLE = "CONNECT_VEHICLE",
    SET_SELECTED_VEHICLE = "SET_SELECTED_VEHICLE",
    SET_AVAILABLE_VEHICLE = "SET_AVAILABLE_VEHICLE",
    CONFIRM_COMMAND = "CONFIRM_COMMAND",
    INIT_MQTT_CONNECTION = "INIT_MQTT_CONNECTION",
    SEND_COMMAND = "SEND_COMMAND",
    DISCONNECT = "DISCONNECT",
    SET_VIEW = "SET_VIEW",
    SET_SCREEN = "SET_SCREEN",
    UPDATE_WAYPOINTS_INFO = "UPDATE_WAYPOINTS_INFO",
    CLEAR_PATH = "CLEAR_PATH",
    FULLSCREEN = "FULLSCREEN",
    RESIZE_WINDOW = "RESIZE_WINDOW",
    SET_USER_INFO = "SET_USER_INFO",
    AUTHENTICATE_TO_VIDEO_SERVER = "AUTHENTICATE_TO_VIDEO_SERVER",
    CAN_WATCH_STREAM = "CAN_WATCH_STREAM",
    INIT = "INIT"
}


type UpdateVehicleInfoAction = {type : ActionType.UPDATE_VEHICLE_INFO, dataset : InfoDataset}
export const updateVehicle = (dataset : InfoDataset) => ({type : ActionType.UPDATE_VEHICLE_INFO, dataset})


type SelectedVehicleAction = {type : ActionType.SET_SELECTED_VEHICLE, selectedVehicle : Vehicle}
export const setSelectedVehicle = (selectedVehicle : Vehicle) => ({type : ActionType.SET_SELECTED_VEHICLE, selectedVehicle})

export type  ConnectVehicleAction = { type : ActionType.CONNECT_VEHICLE, selectedVehicle :  Vehicle}
export const connectVehicle = (selectedVehicle : Vehicle) => ({type : ActionType.CONNECT_VEHICLE, selectedVehicle})

export type SetAvailableVehicleAction = {type : ActionType.SET_AVAILABLE_VEHICLE, id : string}
export const setAvailableVehicle = (id : string) => ({type : ActionType.SET_AVAILABLE_VEHICLE, id})

type ConfirmCommandAction = {type : ActionType.CONFIRM_COMMAND, command : Command}
export const confirmCommand = (command : Command) => ({type : ActionType.CONFIRM_COMMAND, command})

export const initMqttConnection = () => ({type : ActionType.INIT_MQTT_CONNECTION})

export type SendCommandAction = {type : ActionType.SEND_COMMAND, command : Command}
export const sendCommand = (command : Command) => ({type: ActionType.SEND_COMMAND, command})

type SetDisconnectedVehicleAction = {type : ActionType.SET_DISCONNECTED_VEHICLE, id :string}
export const setDisconnectedVehicle = (id : string) => ({type : ActionType.SET_DISCONNECTED_VEHICLE, id})

export const disconnect = () => ({type : ActionType.DISCONNECT})

type SetScreenAction = ({type : ActionType.SET_SCREEN, screen : ScreenType})
export const setScreen = (screen : ScreenType) => ({type : ActionType.SET_SCREEN, screen})

type SetViewAction = {type : ActionType.SET_VIEW, view : ViewType}
export const setView = (view : ViewType) => ({type : ActionType.SET_VIEW, view : view})

type SetWaypointsAction  = {type : ActionType.UPDATE_WAYPOINTS_INFO, dataset : WaypointDataset}
export const setWaypoints = (dataset : WaypointDataset) => ({type : ActionType.UPDATE_WAYPOINTS_INFO, dataset})

type ClearPathAction = {type: ActionType.CLEAR_PATH, vehicle : Vehicle, path : "load" | "draw"}
export const clearPath = (vehicle : Vehicle, path : "load" | "draw") => ({type : ActionType.CLEAR_PATH, vehicle, path})

type SetFullscreenAction = {type: ActionType.FULLSCREEN}
export const setFullscreen = () => ({type : ActionType.FULLSCREEN})

type ResizeWindowAction = {type : ActionType.RESIZE_WINDOW, windowDimensions : {width : number, height : number}}
export const resizeWindow = (windowDimensions : {width : number, height : number}) => ({type : ActionType.RESIZE_WINDOW, windowDimensions})

type SetUserInfo = {type : ActionType.SET_USER_INFO, info : UserInfo}
export const setUserInfo = (info : UserInfo) => ({type : ActionType.SET_USER_INFO, info})

export const authenticateToVideoServer = () => ({type : ActionType.AUTHENTICATE_TO_VIDEO_SERVER})

type CanWatchStream = {type : ActionType.CAN_WATCH_STREAM, canWatchStream : boolean}
export const setCanWatchStream = (canWatchStream : boolean) => ({type : ActionType.CAN_WATCH_STREAM, canWatchStream})

export const setInit =  () => ({type : ActionType.INIT})

type Actions =  CanWatchStream | SetUserInfo | ResizeWindowAction | SetFullscreenAction | ClearPathAction | SetWaypointsAction  | SetViewAction | UpdateVehicleInfoAction
    | SelectedVehicleAction | ConfirmCommandAction | ConnectVehicleAction  | SetAvailableVehicleAction | SetDisconnectedVehicleAction | SetScreenAction



const defaultData = (name : string) => ({
    vehicleId : name,
    precisionCircle : [],
    system: {
        mode: VehicleMode.UNDEFINED,
        missionInProgress: MissionType.NONE,
        regulEnable : false,
        safetyTrigger: SafetyTrigger.UNDEFINED,
        temperature : 0,
        brakeOn : false,
        status: {
            NAV: Status.UNDEFINED,
            IMU : Status.UNDEFINED,
            vision: Status.UNDEFINED,
            engine: Status.UNDEFINED,
            linearActuator: Status.UNDEFINED,
            wheelsCoders: Status.UNDEFINED
        }
    },
    vehicleInfo: {
        speedKmh: 0,
        travelledDistanceKm: 0,
        latDeg: 48.61320008650000,
        longDeg: 7.720241482326000,
        steerAngleDeg: 0,
        headingDeg: 0,
        pitchDeg: 0,
        rollDeg: 0,
        originLat : 0,
        originLong : 0
    },
    covCircle : {
        circle : []
    },
    jetson : {
        stream : false
    },
    battery: {
        status: true,
        low : false,
        voltageV: 43.2,
        currentA: 0,
        powerW: 0,
        consumptionAh: 0
    },
})


const createVehicle = (name : string, image : string) : Vehicle=> ({
    id : name,
    image : image,
    dataset : defaultData(name),
    waypoints : [],
    realPath : [],
    available : false,
    stream : Command.STREAMING_DISABLE,
    missionButton : Command.START_MISSION,
})

export const defaultVehicle = createVehicle("UNDEFINED", "/icon/menu/question_mark.png")


const initialState = {
    vehicleList : config.robots.map((item) =>  createVehicle(item.id, item.image)),
    selectedVehicle : defaultVehicle,
    missionButton : Command.START_MISSION ,
    activeScreen : ScreenType.HOME,
    connectionToast : {
        type : "",
        value : ""
    },
    fullscreen : false,
    view : ViewType.MAP,
    map : {
        displayLoadPath : true,
    },
    userInfo : {
        role : process.env.REACT_APP_AUTHENTICATION === "NO" ? "admin" : "",
        accessToken : ""
    },
    canWatchStream : false,
    windowDimensions : {width : window.innerWidth, height : window.innerHeight},
}

export const mainReducer = (state = initialState, action : Actions) => {
    switch (action.type) {
        case ActionType.UPDATE_VEHICLE_INFO: {
            const vehicle = state.vehicleList.find((item) => item.id === action.dataset.vehicleId)

            if (!vehicle) {
                console.error("SET_AVAILABLE_VEHICLE unknown vehicle id : ", action.dataset.vehicleId)
                return state
            }

            const round = 1000000

            //round coordinates to the 5th digit to check if distance between the last register coordinates is worth displaying another point
            const newLng = Math.round((action.dataset.vehicleInfo.longDeg + Number.EPSILON) * round)
            const newLat = Math.round((action.dataset.vehicleInfo.latDeg + Number.EPSILON) * round)

            const [lastLng, lastLat] = vehicle.realPath.length > 0 ? vehicle.realPath.slice(-1).flatMap((value) =>
                [Math.round((value[0] + Number.EPSILON) * round), Math.round((value[1] + Number.EPSILON) * round)]) : [0, 0]

            action.dataset.covCircle.circle = action.dataset.covCircle.circle.map(item => [item[1], item[0]])

            const updatedInfo = {
                ...vehicle,
                dataset : action.dataset,
                ...((action.dataset.vehicleInfo.originLat != state.selectedVehicle.dataset.vehicleInfo.originLat ||
                    action.dataset.vehicleInfo.originLong != state.selectedVehicle.dataset.vehicleInfo.originLong)
                    && {realPath : []}),
                ...((Math.abs(newLng - lastLng) > 1 || Math.abs(newLat - lastLat) > 1) && {realPath : [...vehicle.realPath, [action.dataset.vehicleInfo.longDeg, action.dataset.vehicleInfo.latDeg]]})
            }


            return {
                ...state,
                vehicleList: refreshArray(state.vehicleList, state.vehicleList.indexOf(vehicle), updatedInfo),
                ...((vehicle.id === state.selectedVehicle.id) && {selectedVehicle : updatedInfo})

            }
        }
        case ActionType.SET_AVAILABLE_VEHICLE: {
            const updatedItem = state.vehicleList.find((item) => item.id === action.id)

            if (!updatedItem) {
                console.error("SET_AVAILABLE_VEHICLE unknown vehicle id : ", action.id)
                return state
            }

            return {
                ...state,
                ...(!updatedItem.available && {connectionToast: {
                    type : "connection",
                    value : `${action.id} connected`
                }}),
                vehicleList: refreshArray(state.vehicleList, state.vehicleList.indexOf(updatedItem), {...updatedItem, available: true})
            }
        }
        case ActionType.SET_DISCONNECTED_VEHICLE : {
            const updatedItem = state.vehicleList.find((item) => item.id === action.id) as Vehicle

            return {
                ...state,
                ...(state.selectedVehicle.id === updatedItem.id && {
                    selectedVehicle:  initialState.selectedVehicle,
                    view : ViewType.MAP,
                    connectionToast:  {type : "disconnection", value : `${updatedItem.id} disconnected`},
                    displayLoadPath : true,
                    activeScreen : ScreenType.HOME
                }),
                vehicleList: refreshArray(state.vehicleList, state.vehicleList.indexOf(updatedItem), {...updatedItem, available: false})
            }
        }
        case ActionType.SET_SELECTED_VEHICLE: {
            return {...state,
                commandButton: "start",
                displayLoadPath : true,
                canWatchStream : false,
                selectedVehicle:  action.selectedVehicle,
                activeScreen : ScreenType.HOME,
                view: ViewType.MAP
            }
        }
        case ActionType.CONFIRM_COMMAND : {

            return {...state,
                vehicleList: refreshArray(
                    state.vehicleList,
                    state.vehicleList.indexOf(state.selectedVehicle), {
                        ...state.selectedVehicle,
                        ...((action.command === Command.STREAMING_ENABLE || action.command === Command.STREAMING_DISABLE) && {stream : action.command}) ,
                        ...((action.command === Command.STOP_MISSION ||  action.command === Command.START_MISSION) && {missionButton : action.command}),
                    }
                )
            }

        }
        case ActionType.SET_SCREEN : {
            return {...state, activeScreen: action.screen}
        }
        case ActionType.SET_VIEW : {
            return  {...state, view: action.view}
        }
        case ActionType.UPDATE_WAYPOINTS_INFO : {
            const vehicle = state.vehicleList.find((item) => item.id === action.dataset.vehicleId)

            if (!vehicle) {
                console.error("SET_AVAILABLE_VEHICLE unknown vehicle id : ", action.dataset.vehicleId)
                return state
            }

            const updatedVehicle = {
                ...vehicle,
                waypoints: action.dataset.waypoints,
            }

            //don't display load path if it is the same as the previous one and the user erased it
            const displayLoadPath = !(state.selectedVehicle.waypoints.length == action.dataset.waypoints.length &&
            state.selectedVehicle.waypoints.every((u, i) => {
                return u.every((number,j) => number === action.dataset.waypoints[i][j])
            }))

            return {
                ...state,
                ...(!state.map.displayLoadPath && {displayLoadPath}),
                vehicleList: refreshArray(state.vehicleList, state.vehicleList.indexOf(vehicle), updatedVehicle),
                ...((vehicle.id === state.selectedVehicle.id) && {selectedVehicle : updatedVehicle})
            }
        }
        case ActionType.CLEAR_PATH : {

            if (action.path === "draw") {
                return {
                    ...state,
                    vehicleList: refreshArray(state.vehicleList, state.vehicleList.indexOf(action.vehicle), {
                        ...action.vehicle,
                        realPath: []
                    }),
                    ...(state.selectedVehicle === action.vehicle && {
                        selectedVehicle: {
                            ...action.vehicle,
                            realPath: []
                        }
                    })
                }
            } else {
                return {...state, displayLoadPath: false}
            }
        }
        case ActionType.FULLSCREEN : {
            return {
                ...state,
                fullscreen: !state.fullscreen
            }
        }
        case ActionType.RESIZE_WINDOW : {
            return {...state, windowDimensions : action.windowDimensions}
        }
        case ActionType.SET_USER_INFO : {
            return {...state, userInfo : action.info}
        }
        case ActionType.CAN_WATCH_STREAM : {
            return {...state, canWatchStream: action.canWatchStream}
        }
        default:
            return state
    }
}
