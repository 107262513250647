import {useDispatch, useSelector} from "react-redux";
import {connectVehicle, defaultVehicle} from "../../../reducers/mainReducer";
import {Button, Card, Dropdown} from "react-bootstrap";
import {Vehicle} from "../../../interfaces/vehicle";
import React from "react";
import {States} from "../../../interfaces/interface";

const displayList = (vehicleList : Vehicle[]) => {
    const dispatch = useDispatch()

    return vehicleList.map((item, index) => {
        return <Dropdown.Item key={index} disabled={!item.available} onClick={() => dispatch(connectVehicle(item))}>{item.id}</Dropdown.Item>
    })
}

const VehicleList = () => {
    const vehicleList = useSelector((state : States) => state.main.vehicleList)
    const selectedVehicle = useSelector((state : States) => state.main.selectedVehicle)
    const dispatch = useDispatch()


    return (
        <>
            <Card className={"mb-2"}>
                <div className={"d-flex justify-content-center"}>
                    <Card.Img className={`image-fluid`} style={styles.image} src={selectedVehicle.image}/>
                </div>
                <Dropdown >
                    <Dropdown.Toggle   className={"w-100"} variant="flat" id="dropdown-basic">
                        {selectedVehicle.id}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {displayList(vehicleList)}
                    </Dropdown.Menu>
                </Dropdown>
            </Card>
            <Button
                disabled={selectedVehicle.id === defaultVehicle.id}
                variant={ "danger"}
                onClick = {() => dispatch(connectVehicle(defaultVehicle))}
            >
                DISCONNECTION
            </Button>
        </>
    )
}

const styles = ({
    image : {
        width : 200,
        height : 200
    }
})

export default VehicleList;