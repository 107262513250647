import {Command, MissionType} from "../interfaces/packet";
export enum ActionType {

    SEND_MISSION = "SEND_MISSION",
    SET_MISSION_DROPDOWN = "SET_MISSION_DROPDOWN"
}

export type SendMission = {type : ActionType.SEND_MISSION, command : Command.START_MISSION | Command.STOP_MISSION, mission : MissionType }
export const sendMission = (command : Command.START_MISSION | Command.STOP_MISSION, mission : MissionType) => ({type: ActionType.SEND_MISSION, command, mission})

type  SetMissionDropdown = ({type : ActionType.SET_MISSION_DROPDOWN, mission : MissionType})
export const setMissionDropdown = (mission : MissionType) => ({type: ActionType.SET_MISSION_DROPDOWN, mission})

type Actions =  SetMissionDropdown

const initialState = {
    missionDropdown : MissionType.NONE
}

export const configurationReducer = (state = initialState, action : Actions) => {
    switch (action.type) {
        case ActionType.SET_MISSION_DROPDOWN: {
            return {...state, missionDropdown: action.mission}
        }
        default:
            return state
    }
}