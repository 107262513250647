import {Card} from "react-bootstrap";
import BatteryGauge from 'react-battery-gauge'
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {States} from "../../../interfaces/interface";

const BatteryPanel = () => {

    const voltage = useSelector((state : States) => state.main.selectedVehicle.dataset.battery.voltageV)
    const status = useSelector((state : States) => state.main.selectedVehicle.dataset.battery.status)
    const low = useSelector((state : States) => state.main.selectedVehicle.dataset.battery.low)
    const min = 43.2;
    const max = 52
    // eslint-disable-next-line no-nested-ternary
    const percentage = (voltage < 43.2) ? 0 : (voltage > max ? 100 : (voltage - min)/(max - min) * 100)
    const [charging, setCharging] = useState(1)

    useEffect(() => {
        let id : NodeJS.Timeout;

        if (voltage >= 53)
            id = setTimeout(() => {
                if (voltage >= 53)
                    setCharging(charging + 1 >= 100 ? 1 : charging + 2);
                else
                    setCharging(1)
            }, 50)
        if (voltage < 53 && charging != 1)
            setCharging(1)

        return () => {
            clearTimeout(id)
        }

    },[charging, voltage])

    return (
        <Card className={`h-100 panel`}>

            <div className={`d-flex justify-content-center align-content-center position-relative w-100 h-100`}>
                <BatteryGauge
                    /* Battery Gauge seems to have a bug with the charging attribute when set to true, seems to stack previous charging state resulting in buggy display*/
                    /* eslint-disable-next-line no-nested-ternary */
                    value={!status ? 0 : (voltage >= 53 ? charging : percentage)}
                    style={styles.battery}
                    customization={{
                        batteryMeter: {
                            fill: 'green',
                            lowBatteryValue: low ? percentage + 1 : 1,
                            lowBatteryFill: 'red',
                            outerGap: 1,
                            noOfCells: 1,
                            interCellsGap: 1
                        },
                        readingText: {
                            lightContrastColor: '#111',
                            darkContrastColor: '#fff',
                            lowBatteryColor: 'red',
                            fontFamily: 'Helvetica',
                            fontSize: voltage >= 53 || !status ? 0 : 12,
                            showPercentage: true
                        },
                        chargingFlash: {
                            scale : 1,
                            fill: 'orange',
                            animated: false,
                            animationDuration: 1000
                        },
                    }}
                />
                {!status && <img style={styles.warning} className={`image-fluid`} src={"./icon/warning_icon.png"}/>}
            </div>
        </Card>
    )
}

export default BatteryPanel

const styles = ({
    battery : {
        left : 0,
        top: 0,
        bottom : 0,
        right : 0,
        maxWidth : "100%",
        maxHeight : "100%",
        position : "absolute" as 'absolute',
        margin : "auto",
        zIndex : 0
    },
    warning : {
        position: "absolute" as 'absolute',
        margin : "auto",
        left : 0,
        top : 0,
        bottom: 0,
        right: 10,
        maxWidth : "60%",
        maxHeight : "120%",
    }
})