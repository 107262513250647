import {MapDrawMode} from "../interfaces/interface";
export enum ActionType {
    SET_MAP_DRAW_MODE = "SET_MAP_DRAW_MODE",
    SET_MAP = "SET_MAP",
}

type SetMapAction = {type : ActionType.SET_MAP, map : {longitude : number, latitude : number, zoom? : number, control? : boolean, view? : "Map" | "Satellite"} }
export const setMap = (map : {longitude? : number, latitude? : number, zoom? : number, control? : boolean, view? : "Streets" | "Satellite" }) => ({type : ActionType.SET_MAP, map})


export type SetMapDrawModeAction = {type : ActionType.SET_MAP_DRAW_MODE, mapDrawMode : MapDrawMode, waypoints : number[][]}
export const setMapDrawMode = (mapDrawMode : MapDrawMode, waypoints : number[][] = []) => ({type : ActionType.SET_MAP_DRAW_MODE, mapDrawMode, waypoints})


type Actions =  SetMapAction | SetMapDrawModeAction

const initialState = {
    map : {
        longitude : 7.720241482326000,
        latitude : 48.61320008650000,
        zoom : 18,
        control : false,
        view : "Streets",
        mapDrawMode : MapDrawMode.NONE,
        drawWaypoints : [],
    },
}

export const homeReducer = (state = initialState, action : Actions) => {
    switch (action.type) {
        case ActionType.SET_MAP : {
            return {...state, map : {...state.map,...action.map}}
        }
        case ActionType.SET_MAP_DRAW_MODE : {
            return {...state, map: {...state.map, drawWaypoints: action.waypoints, mapDrawMode: action.mapDrawMode}}
        }
        default:
            return state
    }
}