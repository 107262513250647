import {Button, Card, Col, Row} from "react-bootstrap";
import {Command} from "../../../interfaces/packet";
import { sendCommand} from "../../../reducers/mainReducer";
import React from "react";
import {useDispatch} from "react-redux";

const SystemPanel = () => {
    const dispatch = useDispatch()




    return (
        <Card className={"panel d-flex justify-content-center "}>
            <h5>System</h5>
            <Row className={"d-flex justify-content-evenly align-items-center w-100 h-100 m-0"}>
                <Col xs={12} sm={5}>
                    <Row className={"d-flex justify-content-center align-items-center m-0 p-0"}>
                        <Col xs={12} lg={6} className={"d-flex justify-content-center"}>
                            <Button
                                variant={"danger w-100"}
                                onClick={() => dispatch(sendCommand(Command.SHUTDOWN))}
                            >
                                SHUTDOWN
                            </Button>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Button
                                variant={"important w-100 mt-3 mt-lg-0"}
                                onClick = {() => dispatch(sendCommand(Command.RESET))}
                            >
                                RESET
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={7} className={"mt-3 mt-sm-0"}>
                    <Row className={"d-flex justify-content-center  align-items-center w-100 h-100 m-0 p-0"} >
                        <Col xs={12} lg={3}>
                            <Button
                                variant={"success w-100"}
                                onClick = {() => dispatch(sendCommand(Command.POWER_ON))}
                            >
                                POWER
                            </Button>
                        </Col>
                        <Col xs={12} lg={3}>
                            <Button
                                variant={"grey w-100 mt-3 mt-lg-0"}
                                onClick = {() => dispatch(sendCommand(Command.OFF))}
                            >
                                OFF
                            </Button>
                        </Col>
                        <Col xs={12} lg={3}>
                            <Button
                                variant={"black w-100 mt-3 mt-lg-0"}
                                onClick = {() => dispatch(sendCommand(Command.STOP))}
                            >
                                STOP
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    )
}

export default SystemPanel

const styles = ({
    divider : {
        display : "block",
        backgroundColor : "lightgray",
        width : 1,
        minHeight : "100%",
    },
})