
export const deleteInArray = <T>(tab: T[], start: number, nbToDelete: number) => {
    tab.splice(start, nbToDelete)
    return tab
}

export const refreshArray = <T>(tab: T[], index: number, value: T) => {
    return tab.map((e, i) => {
        if (i == index) {
            return value;
        }
        return e
    })
}
