import {Card, Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import GaugeChart from 'react-gauge-chart'
import React from "react";
import {States} from "../../../interfaces/interface";

const SpeedPanel = () => {

    const speed = useSelector((state : States) => state.main.selectedVehicle.dataset.vehicleInfo.speedKmh )
    const dimensions = useSelector((state : States) => state.main.windowDimensions)

    return (
        <Card className={`panel   h-100 position-relative `} >
            <div className={`position-absolute`} style={{ opacity : speed < 0 ? 1 : 0.3, color : speed < 0 ? "red" : "black", ...styles.reverse}}>R</div>

            <Row  className={"d-flex p-0 m-0 w-100 h-100 align-items-center justify-content-center  overflow-hidden"}>
                <Col xs={7} sm={12} md={12} lg={8} xl={9} xxl={8} className={" align-items-center d-flex-inline justify-content-center"} >
                    {/* gauge chart needs to have a static height to avoid a bug when a state is updated  */}
                    <GaugeChart
                        animate={false}
                        nrOfLevels={10}
                        percent={ speed/30}
                        hideText={true}
                        style={gaugeStyle(dimensions.width)}
                    />
                    <div className={"text-center"} >
                        {Math.abs(speed)} km/h
                    </div >
                </Col>
            </Row>
        </Card>
    )
}

export default SpeedPanel

const styles = ({
    reverse : {
        top : 5,
        right : 15,
        fontSize : 20
    }

})

const gaugeStyle = (width : number) => {
    const style = {height : "80px", maxWidth : 300}

    if (width >= 576) {
        style.height = "130px"
    }
    if (width >= 768) {
        style.height = "70px"
    }
    if (width >= 992) {
        style.height = "70px"
    }
    if (width >= 1400) {
        style.height = "100px"
    }
    return style
}