import { Col, Row} from "react-bootstrap";

import React from "react";
import SystemPanel from "./components/SystemPanel";
import MissionPanel from "./components/MissionPanel";

const Configuration = () => {



    return (
        <Row className={`h-100 justify-content-center d-flex  pb-3 pt-3`}>
            <Col>
                <Row>
                    <Col xs={12} xl={9} >
                        <SystemPanel/>
                    </Col>
                    <Col className={"mt-3 mt-xl-0"} xs={12}  xl={3}>
                        <MissionPanel/>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Configuration