import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Row} from "react-bootstrap";
import StatusPanel from "./components/StatusPanel";
import MainPanel from "./components/video-map-panel/MainPanel";
import CompassPanel from "./components/orientation-panel/CompassPanel";
import BatteryPanel from "./components/BatteryPanel";
import SpeedPanel from "./components/SpeedPanel";
import MissionPanel from "./components/MissionPanel";


const Home =  () => {

    return (
        <Col className={`h-100 justify-content-center d-flex align-items-center pb-3 pt-3`}>
            <Row>
                <Row className={"mb-3 m-0 p-0"}>
                    <Col>
                        <MissionPanel/>
                    </Col>
                </Row>
                <Col xs={12}>
                    <Row className={"h-100"}>
                        <Col xs={8} sm={9} xl={9}>
                            <StatusPanel/>
                        </Col>
                        <Col xs={4} sm={3} md={3} lg={3} xl={3}>
                            <BatteryPanel/>
                        </Col>
                    </Row>
                </Col>
                <Col md={12} className={"mt-3"}>
                    <Row className={" mb-0"}>
                        <Col xs={12} md={9}>
                            <MainPanel/>
                        </Col>
                        <Col md={3}>
                            <Row className={"h-100"}>
                                <Col xs={12} sm={6} md={12} lg={12} xl={12} className={"mt-3 mt-md-0"}>
                                    <CompassPanel/>
                                </Col>
                                <Col className={"mt-3 mt-md-3"} xs={12} sm={6} md={12} lg={12} xl={12}>
                                    <SpeedPanel/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
}


export default Home;
