import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {createStore, applyMiddleware, combineReducers} from 'redux'
import {Provider} from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import {mainReducer} from "./reducers/mainReducer";
import App from "./screen/App";
import {reducer as burgerMenu} from 'redux-burger-menu';
import "./style/Root.css"

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import rootSaga from "./sagas/rootSaga";
import {configurationReducer} from "./reducers/configurationReducer";
import {homeReducer} from "./reducers/homeReducer";


const initializeStore = () => {
    const rootReducer = combineReducers({
        main: mainReducer,
        configuration : configurationReducer,
        home : homeReducer,
        burgerMenu
    });
    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
    sagaMiddleware.run(rootSaga);

    return store;
};

export const msalConfig = {
    auth: {
        clientId: "a67e9c19-45ad-4279-9ddb-7c3b79666be4",
        authority: "https://login.microsoftonline.com/f1166c20-6e84-4169-95cd-395fe1212826", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: window.location.href,
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

const msalInstance = new PublicClientApplication(msalConfig);


const store = initializeStore()


ReactDOM.render(
    <Provider store={store}>
        <MsalProvider instance={msalInstance}>
            <div className={"root"}>
                <App/>
            </div>
        </MsalProvider>
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
