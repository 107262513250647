import {Button, Card, Form} from "react-bootstrap";

import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    authenticateToVideoServer,
    defaultVehicle,
    sendCommand, setCanWatchStream,
    setFullscreen,
    setView
} from "../../../../reducers/mainReducer";
import {Role, States, ViewType} from "../../../../interfaces/interface";
import JSMpeg from '@cycjimmy/jsmpeg-player';


// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-var-requires
import {MapPanel} from "./MapPanel";
import {Command} from "../../../../interfaces/packet";
import {useIsAuthenticated} from "@azure/msal-react";

const Video = () => {

    const dispatch = useDispatch()
    const fullscreen = useSelector((state : States) => state.main.fullscreen)
    const stream = useSelector((state : States) => state.main.selectedVehicle.dataset.jetson.stream);
    const canWatchStream = useSelector((state : States) => state.main.canWatchStream)
    const userInfo = useSelector((state : States) => state.main.userInfo)


    try {

        useEffect(() => {
            dispatch(authenticateToVideoServer())
        }, [])

        useEffect(() => {
            if (!canWatchStream)
                return

            const protocol = window.location.protocol.replace('http', 'ws')
            const player = new JSMpeg.VideoElement("#videoWrapper", `${protocol}//${window.location.host}/video`, {
                autoplay: true,
                reconnectInterval: 1000,
            })

            return () => {
                player.destroy()
                dispatch(setCanWatchStream(false))
            }

        }, [canWatchStream]);

        return (
            <>
                <div id="videoWrapper" style={styles.wrapper} >
                </div>
                <Button variant={`map p-1 m-1  d-flex align-items-center position-absolute justify-content-center ${fullscreen && "m-3"}`} style={styles.fullscreenButton} onClick={() => {
                    dispatch(setFullscreen())
                }}>
                    <img alt={"fullscreen"} className={"img-fluid"} src={"/icon/map/fullscreen.png"}/>
                </Button>
                {userInfo.role === Role.ADMIN && <Form.Switch
                    className={`position-absolute m-1 ${fullscreen && "m-3"}`}
                    style={styles.streamToggle}
                    label="Stream"
                    checked={stream}
                    onChange={() => dispatch(sendCommand(stream ? Command.STREAMING_DISABLE : Command.STREAMING_ENABLE))}
                />}
            </>
        )
    } catch (e : unknown) {
        console.log("catch ", e)
        return (
            <div>
                <p>Stream Error</p>
            </div>
        )
    }
}

const MainPanel = () => {

    const dispatch = useDispatch()
    const view = useSelector((state : States) => state.main.view)
    const fullscreen = useSelector((state : States) => state.main.fullscreen)
    const selectedVehicle = useSelector((state : States) => state.main.selectedVehicle);

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const init = async () => {
            document.onfullscreenchange = () => {
                if (document.fullscreenElement === null && fullscreen) {
                    dispatch(setFullscreen())
                }
            }

            try {
                if (fullscreen) {
                    await ref.current?.requestFullscreen()
                } else {
                    if (document.fullscreenElement !== null) {
                        await document.exitFullscreen()
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }
        init()
    }, [fullscreen])


    return (
        <Card className={`h-100 w-100 panel`} style={styles.container}>
            <div className={"w-100 h-100 d-flex justify-content-center align-items-center position-relative"} style={styles.background} ref={ref}>
                {view === ViewType.MAP ? <MapPanel/> : <Video/>}
                <Button
                    className={`position-absolute p-1 m-1 ${fullscreen && "m-3"}`}
                    style={styles.switchButton}
                    onClick={() => {dispatch(setView(view === ViewType.MAP ? ViewType.VIDEO : ViewType.MAP))}}
                    variant={"flat"}
                    disabled={selectedVehicle.id === defaultVehicle.id}

                >
                    {view === ViewType.MAP ? ViewType.VIDEO : ViewType.MAP}
                </Button>
            </div>

        </Card>
    );
}

export default MainPanel

const styles = ({
    container : {
        minHeight : 400
    },
    background : {
        backgroundColor : "black"
    },
    switchButton : {
        width : 80,
        top : 0,
        left : 0
    },
    streamToggle : {
        width: 80,
        bottom : 0,
        left: 0,
        color : "white"
    },
    wrapper : {
        left : 0,
        top : 0,
        right : 0,
        bottom : 0,
        maxWidth : "100%",
        maxHeight : "100%",
        position : "absolute" as 'absolute',
        margin : "auto",
        aspectRatio : "4/3",
        zIndex : 0
    },
    icon : {
        width : 29,
        boxShadow : "2px 3px 1px gray"

    },
    fullscreenButton: {
        bottom : 0,
        right : 0,
        width : 29
    },
})
