import {Button, Card, Col, Dropdown, Row} from "react-bootstrap";
import {Command, MissionType} from "../../../interfaces/packet";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import DropdownItem from "react-bootstrap/DropdownItem";
import {States} from "../../../interfaces/interface";
import {sendMission, setMissionDropdown} from "../../../reducers/configurationReducer";

const MissionPanel = () => {
    const dispatch = useDispatch()
    const regulEnable = useSelector((state : States) => state.main.selectedVehicle.dataset.system.regulEnable)
    const currentMission = useSelector((state : States) => state.main.selectedVehicle.dataset.system.missionInProgress)

    const missionIsNone = currentMission === MissionType.NONE

    return (
        <Card className={"panel h-100"}>
            <h5>Mission</h5>
            <Row className={"d-flex justify-content-evenly align-items-center w-100 h-100 m-0"}>
                <Col xs={6} lg={4} xl={7}>
                    <Dropdown>
                        <Dropdown.Toggle disabled={true} variant="flat w-100" id="dropdown-basic">
                            {currentMission}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {
                                Object.values(MissionType).map((item, key) =>
                                    <DropdownItem key={key} onClick={() => dispatch(setMissionDropdown(item))}>
                                        {item}
                                    </DropdownItem>
                                )
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col xs={4} lg={3} xl={5}>
                    <Button
                        disabled={missionIsNone}
                        variant={(regulEnable ? "danger" : "success") + " w-100" }
                        onClick = {() => dispatch(sendMission(
                            regulEnable ? Command.STOP_MISSION : Command.START_MISSION,
                            currentMission
                        ))}
                    >
                        {regulEnable ? "STOP" : "START"}
                    </Button>
                </Col>
            </Row>
        </Card>

    )
}

export default MissionPanel