import {Card, Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {States} from "../../../interfaces/interface";

const BrakePanel = () => {

    const brakeOn = useSelector((state : States) => state.main.selectedVehicle.dataset.system.brakeOn)
    const [blink, setBlink] = useState(false)

    useEffect(() => {
        let id : NodeJS.Timeout;

        if (brakeOn)
            id = setTimeout(() => {
                if (brakeOn)
                    setBlink(!blink)
                else
                    setBlink(false)
            }, 500)
        return () => {
            clearTimeout(id)
        }
    }, [blink, brakeOn])

    return (
        <Col xs={4} md={2} lg={2} xl={2} className={"justify-content-center d-flex"} >
            <Card.Img
                className={`image-fluid`}
                src={`./icon/status/brake_icon${blink && brakeOn ? "_red" : ""}.png`}
                style={{opacity : brakeOn ? 1 : 0.4, ...styles.icon}}/>
        </Col>
    )
}

const icons = ["./icon/status/antenna_icon", "./icon/status/imu_icon", "./icon/status/eye_icon", "./icon/status/motor_icon", "./icon/status/chassis_icon","./icon/status/wheel_icon"]
const colors = ["_red", "_green", ""]

const statusDisplay = () => {
    const status = useSelector((state : States) => state.main.selectedVehicle.dataset.system.status)

    const arr = Object.keys(status).map((key) => status[key]);
    return icons.map((item, index) => {
        return (
            <Col xs={6} md={2} lg={2} key={index} className={"d-flex justify-content-center mb-2 mb-md-0"} >
                <Card.Img className={`img-fluid`} style={styles.icon} src={`${item}${colors[arr[index]]}.png`}/>
            </Col>
        )
    })
}

const StatusPanel = () => {

    return (
        <Card className={`panel h-100`} >
            <Row className={"d-flex justify-content-evenly align-items-center w-100 h-100"}>
                <Col xs={6} md={9} lg={9} xl={9} className={"p-0 m-0"}>
                    <Row className={"p-0 m-0 d-flex justify-content-evenly w-100 "}>
                        {statusDisplay()}
                    </Row>
                </Col>
                <Col xs={"auto"} className={"d-flex align-items-center h-100"}><div style={styles.divider}/></Col>
                <BrakePanel/>
            </Row>
        </Card>
    )
}

export default StatusPanel

const styles = ({
    icon : {
        maxWidth : 50,
        minWidth : 20,
    },
    divider : {
        backgroundColor : "lightgray",
        width : 2,
        height : "80%"
    },
})