import {Card, Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import React from "react";
import Inclination from "./Inclination";
import {States} from "../../../../interfaces/interface";

const CompassPanel = () => {
    const headingDirectionAngle = useSelector((state : States) => state.main.selectedVehicle.dataset.vehicleInfo.headingDeg)
    const frontWheelAngle = useSelector((state : States) => state.main.selectedVehicle.dataset.vehicleInfo.steerAngleDeg)
    const backWheelAngle = 0;

    return (
        <Card className={`panel h-100`}>
            <Row className={"align-items-center justify-content-center d-flex"}>
                <Inclination/>
                <Col xs={8} sm={10} lg={10} xl={10}>
                    <div style={styles.compass}>
                        <div className={" card-img-overlay"} style={{...styles.block, transform : `rotate(${headingDirectionAngle}deg)`}}>

                            <img src={"./kipp_top.png"} style={{...styles.car}}/>
                            <div style={{...styles.wheelFrontLeft, ...styles.blockDiv, transform : `rotate(${frontWheelAngle > 25 || frontWheelAngle < -25 ? 0 : frontWheelAngle}deg)`}}/>
                            <div style={{...styles.wheelFrontRight, ...styles.blockDiv, transform : `rotate(${frontWheelAngle > 25 || frontWheelAngle < -25 ? 0 : frontWheelAngle}deg)`}}/>
                            <div style={{...styles.wheelBottomLeft, ...styles.blockDiv, transform : `rotate(${backWheelAngle}deg)`}}/>
                            <div style={{...styles.wheelBottomRight, ...styles.blockDiv, transform : `rotate(${backWheelAngle}deg)`}}/>
                        </div>
                    </div>
                </Col>
            </Row>
        </Card>
    )
}

export default CompassPanel

const styles  = ({
    car : {
        width: "60%",
        height: "25%",
        transform: "rotate(90deg)",
        position: "absolute" as 'absolute',
        objectFit: "cover" as 'cover',
        overflow: "hidden" as 'hidden',
    },
    wheelFrontLeft : {
        top: "22%",
        left: "34%",
    },
    wheelFrontRight : {
        top: "22%",
        left: "61%"
    },
    wheelBottomLeft : {
        bottom : "28%",
        left : "34%"
    },
    wheelBottomRight : {
        bottom : "28%",
        left : "61%"
    },
    compass : {
        alignItems : "center",
        justifyContent: "center",
        overflow: "hidden",
        display: "flex",
        backgroundImage: `url(/icon/compass.png)`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        aspectRatio: "1",
    },
    blockDiv : {
        transformOrigin: "center",
        display: "block",
        maxWidth: 100,
        maxHeight: 160,
        width: "5%",
        height: "12%",
        aspectRatio: "auto",
        borderRadius: 10,
        position: "absolute" as 'absolute',
        objectFit: "cover" as 'cover',
        resize: "both" as 'both',
        backgroundImage: `url("/kipp_wheel.png")`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
    },
    block : {
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        padding: 10,
        position: "relative" as 'relative',
        width: "90%",
        height: "90%",
    }
})