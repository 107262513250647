import {slide as Menu} from 'react-burger-menu';
import {action as toggleMenu, decorator as reduxBurgerMenu} from 'redux-burger-menu';
import {useDispatch, useSelector} from "react-redux";
import React from "react";
import VehicleList from "./vehicle-panel/VehicleList";
import { Container, Stack} from "react-bootstrap";
import {defaultVehicle, setScreen} from "../../reducers/mainReducer";
import {Role, ScreenType, States} from "../../interfaces/interface";


const BurgerMenu = () => {
    const isOpen = useSelector((state : {burgerMenu : {isOpen : boolean}}) => state.burgerMenu.isOpen)
    const activeScreen = useSelector((state : States) => state.main.activeScreen)
    const dispatch = useDispatch()
    const userInfo = useSelector((state : States) => state.main.userInfo)
    const selectedVehicle = useSelector((state : States) => state.main.selectedVehicle)

    return (
        <Menu  isOpen={isOpen} noTransition onClose={ () => dispatch(toggleMenu(false))  } disableAutoFocus>
            <Container>
                <Stack className={"pb-2"}>
                    <VehicleList/>
                    <div style={styles.divider}/>

                    <Stack gap={3} className={"mt-1 mb-1"}>
                        <div className={"d-flex align-items-center"}  onClick={() => dispatch(setScreen(ScreenType.HOME))} style={{...styles.nav, opacity : activeScreen === ScreenType.HOME ? 1 : 0.3}}>
                            <img className={`img-fluid`} style={styles.menuIcon} src={"./icon/menu/home.png"}/>
                            <span style={styles.text}>Home</span>
                        </div>
                        {userInfo.role === Role.ADMIN && selectedVehicle != defaultVehicle &&
                            <div onClick={() => dispatch(setScreen(ScreenType.CONFIGURATION))} style={{...styles.nav, opacity : activeScreen === ScreenType.CONFIGURATION ? 1 : 0.3}} >
                                <img className={"img-fluid"} style={styles.menuIcon} src={"./icon/menu/configuration.png"}/>
                                <span style={styles.text}>Configuration</span>
                            </div>
                        }
                    </Stack>
                    <div style={styles.divider}/>
                </Stack>
            </Container>
        </Menu>
    )
}

export default reduxBurgerMenu(BurgerMenu)

const styles = ({
    text : {
        marginLeft : 30
    },
    menuIcon : {
        maxWidth : 20
    },
    nav : {
        cursor : "pointer"
    },
    divider : {
        backgroundColor : "lightgray",
        marginLeft : "5%",
        width : "90%",
        height : 1,
        marginBottom : 1 + "rem",
        marginTop : 1 + "rem"
    }
})