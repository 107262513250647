

export enum VehicleMode {
    INIT = "init",
    IDLE = "idle",
    MANUAL_ENGAGING = "manual engaging",
    AUTO_ENGAGING ="auto engaging",
    MANUAL_ENGAGED= "manual engaged",
    AUTO_ENGAGED= "auto engaged",
    STOPPING_VEHICLE = "stopping vehicle",
    LOW_BATTERY = "low battery",
    SAFE = "safe",
    SHUTTING_DOWN = "shutting down",
    OFF = "off",
    UNDEFINED = "undefined"
}

export enum Command {
    ENGAGE_AUTO = "engageAuto",
    STOP_MISSION = "stopMission",
    START_MISSION = "startMission",
    POWER_ON = "powerOn",
    OFF = "off",
    STOP = "stop",
    STREAMING_ENABLE = "streamingEnable",
    STREAMING_DISABLE = "streamingDisable",
    SHUTDOWN = "shutdown",
    RESET = "reset",
}

export enum SafetyTrigger {
    NO_TRIGGER= "no trigger" ,
    SUP_ECU_SAFE = "sup ecu safe",
    MAIN_ECU_SAFE = "main ecu safe",
    BAT_ECU_SAFE = "bat ecu safe",
    LHMI_ECU_SAFE = "lhmi ecu safe",
    CHAL_MAIN_NOK = "chal main nok",
    CHAL_LHMI_NOK = "chal lhmi nok",
    CHAL_BAT_NOK = "chal bat nok",
    CHAL_SUP_NOK= "chal sup nok",
    BAT_NOK = "bat nok",
    SUP_CAN_SFTY_NOK = "sup can sfty nok",
    SUP_CAN_DATA_NOK = "sup can data nok",
    MAIN_CAN_SFTY_NOK = "main can sfty nok",
    MAIN_CAN_DATA_NOK = "main can data nok",
    MAIN_CAN_CO_NOK = "maon can co nok",
    ENGINE_NOK = "engine nok",
    LINACT_NOK = "linact nok",
    IMU_NOK ="imu nok",
    WENC_NOK= "wenc nok",
    SUP_REMOTE_NOK= "sup",
    MAIN_REMOTE_NOK= "",
    UNDEFINED = "undefined"
}

export enum MissionType {
    NONE = "none",
    PATH_PLANNING = "path planning",
    FOLLOW_ME = "follow me",
}

export enum Status {
    ERROR = 0,
    OK = 1,
    UNDEFINED = 2
}


export enum MessageType {
    UNDEFINED = 0,
    VEHICLE_INFO,
    WAYPOINTS,
    COMMAND,
    CONNECTED_DEVICE,
    DISCONNECTED_DEVICE,
    REQUEST_DATASET,
    WHO_IS_CONNECTED,
    DISCONNECTED_CLIENT,
    COMMAND_CONFIRMATION,
    MISSION,
    DRAW_WAYPOINTS
}

export interface DrawWaypointsPacket {
    messageType : MessageType.DRAW_WAYPOINTS
    waypoints : number[][]
}


export interface MissionPacket {
    messageType : MessageType.MISSION
    command : Command.START_MISSION | Command.STOP_MISSION
    missionType : MissionType
}

export interface CommandPacket  {
    messageType : MessageType.COMMAND,
    command : Command
}

export interface CommandConfirmationPacket  {
    messageType : MessageType.COMMAND_CONFIRMATION,
    command : Command
}


export interface RequestPacket {
    messageType : MessageType.REQUEST_DATASET
    clientId : string
    request : boolean
}

export interface WhoIsConnectedPacket {
    messageType : MessageType.WHO_IS_CONNECTED
}

export interface DisconnectedClientPacket {
    messageType : MessageType.DISCONNECTED_CLIENT
    clientId : string
}

export interface WaypointsPacket {
    messageType : MessageType.WAYPOINTS
    data : WaypointDataset
}

export interface WaypointDataset {
    vehicleId : string
    waypoints: number[][]
}

export interface InfoDataset {
    vehicleId: string
    system: {
        mode: VehicleMode,
        missionInProgress: MissionType,
        regulEnable : boolean,
        safetyTrigger: SafetyTrigger,
        brakeOn : boolean,
        temperature : number
        status: {
            NAV: Status,
            IMU : Status
            vision: Status,
            engine: Status,
            linearActuator: Status,
            wheelsCoders: Status
        }
    },
    vehicleInfo: {
        speedKmh: number,
        travelledDistanceKm: number,
        latDeg: number,
        longDeg: number,
        steerAngleDeg: number,
        headingDeg: number,
        pitchDeg: number,
        rollDeg: number,
        originLat : number,
        originLong : number
    },
    covCircle : {
        circle : number[][]
    },
    jetson : {
        stream : boolean
    },
    battery: {
        status : boolean
        low : boolean
        voltageV: number,
        currentA: number,
        powerW: number,
        consumptionAh: number
    },
}

export interface ConnectedPacket {
    messageType : MessageType.CONNECTED_DEVICE
    id : string
}

export interface DisconnectedDevicePacket {
    messageType : MessageType.DISCONNECTED_DEVICE
    id : string
}

export interface InfoPacket  {
    messageType : MessageType.VEHICLE_INFO,
    data : InfoDataset
}

export type SendPackets = CommandPacket | RequestPacket | WhoIsConnectedPacket | DisconnectedClientPacket | MissionPacket | DrawWaypointsPacket
export type ReceivePackets = InfoPacket | ConnectedPacket | DisconnectedDevicePacket | CommandConfirmationPacket | WaypointsPacket