import {Button, Row, Stack} from "react-bootstrap";

import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {clearPath, defaultVehicle, setFullscreen} from "../../../../reducers/mainReducer";
import 'mapbox-gl/dist/mapbox-gl.css';
import Map, {Layer, MapProvider, Marker, NavigationControl, Source, useMap} from "react-map-gl";
import mapboxgl from 'mapbox-gl';
import {MapDrawMode, States} from "../../../../interfaces/interface";
import {setMap, setMapDrawMode} from "../../../../reducers/homeReducer";

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

// eslint-disable-next-line @typescript-eslint/no-var-requires
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


//sk.eyJ1IjoidGFuZHMiLCJhIjoiY2wydTVvZXJvMGF6cjNtbzJleHEyMGZpaiJ9.wOjalyS8HOifllziy2FH8g

const Menu = () => {
    const dispatch = useDispatch();
    const {map} = useMap()
    const selectedVehicle = useSelector((state : States) => state.main.selectedVehicle);
    const mapState = useSelector((state : States) => state.home.map);
    const [trash, setTrash] = useState(false)

    map?.on("render", () => {
        map?.resize()
    })

    return (
        <>
            {mapState.mapDrawMode === MapDrawMode.DRAWING && <Row className={"position-absolute d-flex justify-content-center"} style={styles.drawMenu}>
                <Button variant={`map p-1 m-2  d-flex align-items-center justify-content-center`} style={styles.icon}
                    onClick={() => {
                        dispatch(setMapDrawMode(MapDrawMode.VALIDATE))
                    }}>
                    <img alt={"draw"} className={"img-fluid"} src={"/icon/map/check.png"}/>
                </Button>
                <Button variant={`map p-1 m-2  d-flex align-items-center justify-content-center`} style={styles.icon}
                    onClick={() => {
                        const cpy = [...mapState.drawWaypoints]
                        cpy.splice(mapState.drawWaypoints.length - 1  , 1)
                        dispatch(setMapDrawMode(MapDrawMode.DRAWING, cpy.length === 1 ? [] : cpy))
                    }}>
                    <img alt={"draw"} className={"img-fluid"} src={"/icon/map/arrow.png"}/>
                </Button>
                <Button variant={`map p-1 m-2  d-flex align-items-center justify-content-center`} style={styles.icon}
                    onClick={() => {
                        dispatch(setMapDrawMode(MapDrawMode.NONE))
                    }}>
                    <img alt={"draw"} className={"img-fluid"} src={"/icon/map/close.png"}/>
                </Button>
            </Row>}
            <Stack className={"position-absolute"}  style={styles.sideMenu}>
                <Button  variant={`map p-1 mb-2 d-flex align-items-center justify-content-center`} style={styles.icon} onClick={() => {
                    if (selectedVehicle.id === defaultVehicle.id)
                        return;
                    if (map) {
                        map.flyTo({
                            animate: false,
                            center: [selectedVehicle.dataset.vehicleInfo.longDeg, selectedVehicle.dataset.vehicleInfo.latDeg]
                        })
                        dispatch(setMap({control: false}))
                    }
                }}>
                    <img alt={"lock"} className={"img-fluid"} src={"/icon/map/padlock.png"}/>
                </Button>

                <div className={"d-flex-inline justify-content-center align-items-center position-relative"}>
                    <Button  variant={`map p-1 mb-2 d-flex align-items-center justify-content-center`} style={styles.icon}  onClick={() => {
                        setTrash(!trash)
                    }}>
                        <img alt={"delete"} className={"img-fluid"} src={"/icon/map/delete.png"}/>
                    </Button>
                    {trash &&
                        <div className={"d-flex h-100 pb-2 position-absolute me-2"} style={{top : 0, right : 29}}>
                            <Button variant={`map p-1 me-2 d-flex align-items-center justify-content-center`} style={{...styles.icon}} onClick={() => {
                                setTrash(!trash)
                                dispatch(clearPath(selectedVehicle, "draw"))
                            }}>
                                <div style={{height : "80%", width: 3, backgroundColor : "rgb(199,22,22)"}}/>
                            </Button>
                            <Button  variant={`map p-1 d-flex align-items-center justify-content-center`} style={styles.icon}  onClick={() => {
                                setTrash(!trash)
                                dispatch(clearPath(selectedVehicle, "load"))

                            }}>
                                <div style={{minHeight : "80%", width: 3, backgroundColor : "rgb(23,194,26)"}}/>

                            </Button>
                        </div>
                    }
                </div>

                <Button  variant={`map p-1 mb-2  d-flex align-items-center justify-content-center`} style={styles.icon}   onClick={() => {
                    dispatch(setMap({view : mapState.view === "Streets" ? "Satellite" : "Streets"}))
                }}>
                    <img alt={"map view"} className={"img-fluid"} src={mapState.view === "Streets" ? "/icon/map/satellite.png" : "/icon/map/map.png"}/>
                </Button>

                <Button variant={`map p-1 mb-2 d-flex align-items-center justify-content-center`} style={styles.icon} onClick={() => {
                    dispatch(setFullscreen())
                }}>
                    <img alt={"fullscreen"} className={"img-fluid"} src={"/icon/map/fullscreen.png"}/>
                </Button>
                <Button variant={`map p-1  d-flex align-items-center justify-content-center`} style={styles.icon} onClick={() => {
                    dispatch(setMapDrawMode(mapState.mapDrawMode != MapDrawMode.NONE ? MapDrawMode.NONE : MapDrawMode.DRAWING))
                }}>
                    <img alt={"draw"} className={"img-fluid"} src={"/icon/map/pen.png"}/>
                </Button>
            </Stack>
        </>
    );
}



export const MapPanel = () => {

    const mapState = useSelector((state : States) => state.home.map);
    const selectedVehicle = useSelector((state : States) => state.main.selectedVehicle);
    const displayLoadPath = useSelector((state : States) => state.main.map.displayLoadPath);
    const dispatch = useDispatch();
    const headingDirectionAngle = useSelector((state : States) => state.main.selectedVehicle.dataset.vehicleInfo.headingDeg)

    const markers = useMemo(() =>
        <Marker longitude={selectedVehicle.dataset.vehicleInfo.longDeg} latitude={selectedVehicle.dataset.vehicleInfo.latDeg}>
            <img src={"./kipp_wheels.png"} style={{...styles.marker, transform : `rotate(${headingDirectionAngle + 90}deg)`}}/>
        </Marker>, [selectedVehicle.dataset.vehicleInfo.latDeg, selectedVehicle.dataset.vehicleInfo.longDeg, selectedVehicle.dataset.vehicleInfo.headingDeg]
    );

    const drawingPath = useMemo( () =>
        <Source id={"drawingPath"} type={"geojson"} data={{
            type: "Feature",
            properties: {},
            geometry: {
                type: "LineString",
                coordinates: mapState.drawWaypoints
            }}}>
            <Layer
                id="drawingPathLines"
                type="line"
                source="drawingPath"
                layout={{
                    "line-join": "round",
                    "line-cap": "round"
                }}
                paint={{
                    "line-color": "rgb(23,77,194)",
                    "line-width": 5
                }}
            />
        </Source>, [mapState.drawWaypoints]
    )

    const drawCovCircle = useMemo( () =>
        <Source id={"PrecisionCircle"} type={"geojson"} data={{
            type: "Feature",
            properties: {},
            geometry: {
                type: "LineString",
                coordinates: selectedVehicle.dataset.covCircle.circle
            }
        }}>
            <Layer
                id="precisionCircleLines"
                type="line"
                source="precisionCircle"
                layout={{
                    "line-join": "round",
                    "line-cap": "round"
                }}
                paint={{
                    "line-color": "rgb(99,28,173)",
                    "line-width": 3
                }}
            />
        </Source>, [selectedVehicle.dataset.covCircle.circle])



    const missionPath = useMemo( () =>
        <Source id={"missionPath"} type={"geojson"} data={{
            type: "Feature",
            properties: {},
            geometry: {
                type: "LineString",
                coordinates: selectedVehicle.waypoints
            }}}>
            <Layer
                id="lineLayer"
                type="line"
                source="missionPath"
                layout={{
                    "line-join": "round",
                    "line-cap": "round"
                }}
                paint={{
                    "line-color": "rgb(23,194,26)",
                    "line-width": 5
                }}
            />
        </Source>, [selectedVehicle.waypoints]
    )

    const realPath = useMemo( () =>
        <Source id={"realPath"} type={"geojson"} data={{
            type: "Feature",
            properties: {},
            geometry: {
                type: "LineString",
                coordinates: selectedVehicle.realPath
            }}}>
            <Layer
                id="realPathLines"
                type="line"
                source="realPath"
                layout={{
                    "line-join": "round",
                    "line-cap": "round"
                }}
                paint={{
                    "line-color": "rgb(199,22,22)",
                    "line-width": 5
                }}
            />
        </Source>, [selectedVehicle.realPath])


    try {
        return (
            <MapProvider>
                <Map
                    id={"map"}
                    mapboxAccessToken={"pk.eyJ1IjoidGFuZHMiLCJhIjoiY2wydDB1d2J2MDQzajNrbzNsZDl4YzFycCJ9.__IV0FE3ijKqjRNDwSbtCA"}
                    longitude={mapState.control ? mapState.longitude : selectedVehicle.dataset.vehicleInfo.longDeg}
                    latitude={mapState.control ? mapState.latitude : selectedVehicle.dataset.vehicleInfo.latDeg}
                    zoom={mapState.zoom}
                    reuseMaps={true}
                    maxTileCacheSize={20}
                    mapStyle={`mapbox://styles/mapbox/${mapState.view === "Streets" ? "streets" : "satellite"}-v9`}
                    onClick={(e) => {
                        try {
                            if (e.lngLat && mapState.mapDrawMode === MapDrawMode.DRAWING) {
                                dispatch(setMapDrawMode(MapDrawMode.DRAWING, [...mapState.drawWaypoints, [e.lngLat.lng, e.lngLat.lat]]))
                            }
                        } catch (e) {
                            console.log(e)
                        }
                    }}
                    onMove={(evt) => {
                        const position = mapState.control ? {
                            longitude: evt.viewState.longitude,
                            latitude: evt.viewState.latitude
                        } : {}
                        dispatch(setMap({...position, zoom: evt.viewState.zoom, control: true}))
                    }}
                    maxZoom={19}
                >
                    {selectedVehicle.id !== defaultVehicle.id && markers}
                    {displayLoadPath && missionPath}
                    {realPath}
                    {drawCovCircle}
                    {mapState.mapDrawMode == MapDrawMode.DRAWING && drawingPath}
                    <NavigationControl showCompass={false}/>
                </Map>
                <Menu/>
            </MapProvider>
        )
    } catch (e) {
        console.log(e)
        return <p>Something went wrong</p>
    }
}

const styles = ({
    sideMenu : {
        top : 78,
        right : 10
    },
    icon : {
        width : 29,
        boxShadow : "2px 3px 1px gray"
    },
    marker : {
        width : 40
    },
    erasedPath : {
        right : 40,
        top : 111
    },
    drawMenu : {
        top : 0,
    }
})
