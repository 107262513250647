import React from "react";
import { useMsal} from "@azure/msal-react";
import {Button, Card, Container, Row} from "react-bootstrap";
import Header from "../../components/Header";

export const loginRequest = {
    scopes: ["User.Read"]
};


const handleLogin = async (instance) => {


    try {
        await instance.loginRedirect(loginRequest)
    } catch (e) {
        console.log(e)
    }

}

export const LoginScreen = () => {
    const { instance} = useMsal();

    return (
        <Container fluid>
            <Header/>
            <Row className={"vh-100 d-flex justify-content-center align-items-center"}>
                <Card style={{ width: '18rem'}} className={"panel h-50  d-flex justify-content-center align-items-center"}>
                    <Card.Header as={'h5'}>
                        Robot Cloud Dashboard
                    </Card.Header>
                    <Card.Body className={"d-flex align-items-center"}>
                        <Button variant="secondary" className="d-flex align-items-center" onClick={() => handleLogin(instance)}>
                            <img alt={"microsoft-logo"} className={"m-1"} src={"https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"}/>
                            Sign in with Microsoft
                        </Button>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    );
};
